<template>
  <div class="contact-page">
    <div class="page-header">
      <div class="header-layer">
        <div class="container">
          <div class="header-title">
            <h3>Холбоо барих</h3>
            <span class="divider"></span>
            <div class="header-breadcrumb">
              <router-link to="/">
                <span>Эхлэл</span>
                <i class="isax isax-arrow-right-4"></i>
              </router-link>

              <router-link to="/faq" class="current">
                <span>Холбоо барих</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="contact-page-section">
      <div class="container">
        <div class="inner-container">
          <!--Form Column-->
          <div class="form-column">
            <div class="inner-column">
              <!--Contact Form-->
              <p style="color: #fff;line-height: 1.4em; font-size: 30px; padding-bottom: 45px; font-weight: 700">Санал хүсэлт илгээх</p>
              <div class="contact-form">
                <form @submit.prevent="submitForm">
                  <div class="row clearfix">
                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                      <input type="text" v-model="form.name" placeholder="Нэр" required/>
                    </div>
                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                      <input type="email" v-model="form.email" placeholder="Имэйл" required/>
                    </div>
                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                      <input type="text" v-model="form.subject" placeholder="Гарчиг" required/>
                    </div>
                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                      <textarea v-model="form.message" placeholder="Агуулга"></textarea>
                    </div>
                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                      <button type="submit" class="theme-btn btn-style-one" :disabled="loading">
                        {{ loading ? 'Илгээж байна...' : 'Илгээх' }}
                      </button>
                    </div>
                  </div>
                </form>
                <p v-if="successMessage" class="success-message" style="color: green">{{ successMessage }}</p>
                <p v-if="errorMessage" class="error-message" style="color:red;">{{ errorMessage }}</p>
              </div>
              <!--End Contact Form-->

            </div>
          </div>

          <!--Info Column-->
          <div class="info-column">
            <div class="inner-column">
              <h2>Холбогдох мэдээлэл</h2>
              <ul class="list-info">
                <li><i class="uil uil-map-marker"></i>Блю Скай тауэр, 4 давхар, № 403, Энхтайвны өргөн чөлөө 17, 1-р
                  хороо, Сүхбаатар дүүрэг, Улаанбаатар хот 14240, Монгол улс.
                </li>
                <li><i class="uil uil-envelope"></i>info@bloombergtv.mn</li>
                <li><i class="uil uil-phone-alt"></i>+976 70102020</li>
              </ul>
              <ul class="social-icon-four">
                <li class="follow">Follow on:</li>
                <li><a href="#"><i class="uil uil-facebook"></i></a></li>
                <li><a href="#"><i class="uil uil-twitter"></i></a></li>
                <li><a href="#"><i class="uil uil-instagram-alt"></i></a></li>
                <li><a href="#"><i class="uil uil-youtube"></i></a></li>
                <li><a href="#"><i class="uil uil-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@store/_boot/baseApi"

export default {
  name: "Contact",
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      loading: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.successMessage = "";
      this.errorMessage = "";

      try {
        const response = await api.post(`/api/footer/save/feedback`, this.form);
        console.log("response", response);
        if (response.data.status) {
          this.successMessage = "Амжилттай илгээгдлээ!";
          this.form = {name: "", email: "", subject: "", message: ""}; // Clear form
        } else {
          this.errorMessage = "Алдаа гарлаа. Дахин оролдоно уу.";
        }
      } catch (error) {
        this.errorMessage = "Серверийн алдаа гарлаа!";
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>